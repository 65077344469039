<header class="header-deal">
  <nav class="navbar" role="navigation" style="    display: inherit;">
    <div class="container-deal" style="display: flex">
      <a class="navbar-brand logo" href="https://www.dealtobook.com" (click)="collapseNavbar()" style="flex: 1; align-self: center">
        <img src="assets/img/logo.gif" alt="logo" class="nav-logo"/>
      </a>
      <div >
        <div class="collapse navbar-collapse nav-collapse">
          <div class="menu-container">
            <ul class="nav navbar-nav-deal container-deal-right">
              <li class=" nav-item" style="align-content: space-around;">
                <a class="nav-item-child" [routerLink]="['/']">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-item-child">
                  <input class="dropdown" type="checkbox" id="dropdown" name="dropdown"/>
                  <label class="nav-item-child" for="dropdown">
                    <img [src]="'./assets/img/flags/'+ languageKey + '.png'" alt="flag" class="langimg selected-flag"/>
                    {{ languageKey | findLanguageFromKey }}
                  </label>

                  <div class="section-dropdown">
                    <ng-container *ngFor="let language of languages">
                      <a class="link-new" [jhiActiveMenu]="language" href="javascript:void(0);" (click)="changeLanguage(language);collapseNavbar();">
                        <img alt="flag" class="langimg mr-2 uil uil-arrow-right" [src]="'./assets/img/flags/'+ (language | findFlagFromKey) + '.png'"/>
                        {{ language | findLanguageFromKey }}
                        <!--                  <span [class]="language | findFlagFromKey"></span>-->
                      </a>
                    </ng-container>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>
